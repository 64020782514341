<template>
  <BOverlay :show="isProcessing">
    <h3 class="mb-2">
      {{ $t('setting-module.classroom-student-lab-settings') }}
    </h3>
    <b-table-simple
      responsive
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td> {{ $t('setting-module.prompted-speech-pass') }}</b-td>
          <b-td>
            <b-form-input v-model="optionsLocal.prompted_speech_pass_rate"
                          :placeholder="$t('setting-module.prompted-speech-pass')"
                          @change="updateSchoolSetting('prompted_speech_pass_rate')"
            />
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('prompt_speech_pass')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button></b-td>
        </b-tr>
        <b-tr>
          <b-td>  {{ $t('setting-module.allow-completing-round-afterward') }}</b-td>
          <b-td>
            <b-form-input
              v-model="optionsLocal.complete_round"
              class="custom-control-primary"
              type="number"
              placeholder="Allow completing round afterward"
              @change="updateSchoolSetting('complete_round')"
            />
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('complete_round')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button></b-td>
        </b-tr>
        <b-tr>
          <b-td> {{ $t('setting-module.course-completion') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox
                id="single-double-course-completion-settings"
                v-model="optionsLocal.single_double_course_completion"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('single_double_course_completion')"
              /> <span class="ml-2">{{ $t('setting-module.single-double-bar') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('single_double_course_completion')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button></b-td>
        </b-tr>
        <b-tr>
          <b-td> {{ $t('setting-module.speech-server-check-title') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.speech_server_check) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="speech-server-check"
                v-model="optionsLocal.speech_server_check"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('speech_server_check')"
              /> <span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('speech_server_check')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button></b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.skip-problem-title') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.skip_problem) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="skip-problem-check"
                v-model="optionsLocal.skip_problem"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('skip_problem')"
              /><span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('skip_problem')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.default-individual-report-type') }}</b-td>
          <b-td>
            <span class="d-flex align-items-baseline">
              <b-form-checkbox
                id="individual-report-type"
                v-model="optionsLocal.default_individual_report"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('default_individual_report')"
              /><span class="ml-2">{{ $t('setting-module.regular-scorm') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('default_individual_report')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.multi-practice-title') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.multi_practice) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="multi-practice-check"
                v-model="optionsLocal.multi_practice"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('multi_practice')"
              /><span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('multi_practice')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.word-power-title') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.word_power) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="word-power-check"
                v-model="optionsLocal.word_power"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('word_power')"
              /><span class="ml-2">{{ $t('setting-module.disable-enable') }}</span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('word_power')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.copy-paste') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.copy_paste) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="copy-paste-settings"
                v-model="optionsLocal.copy_paste"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('copy_paste')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }}  </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('copy_paste')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.overriding-last-homework-title') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.overriding_last_homework) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="overriding-last-homework-settings"
                v-model="optionsLocal.overriding_last_homework"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('overriding_last_homework')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }}  </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('overriding_last_homework')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.robot-style-title') }}</b-td>
          <b-td>
            <b-form-select
              v-model="optionsLocal.robot_style"
              :options="robotStyle"
              @change="(value)=>updateSchoolSetting('robot_style')"
            />
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('robot_style')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr v-if="self.usertype === 'district'">
          <b-td>Language Level Indicator</b-td>
          <b-td>
            <span v-for="value,key of optionsLocal.lang_level_indicator"
                  :key="key"
            >
              <b-form-group
                :label="key"
                label-cols-sm="4"
              >
                <b-form-input
                  v-model="optionsLocal.lang_level_indicator[key].label"
                  @change="handleLangLevelChange"
                />
              </b-form-group>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('language_level_indicator')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </BOverlay>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd, BButton,
  BFormSelect,
  BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
import { getUserData } from '@/auth/utils'

export default {
  name: 'OtherSettings',
  components: {
    BOverlay,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BFormSelect,
    SettingInfoModal,
  },
  props: {
    optionsLocal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // generalData: {},
      // optionsLocal: {},
      isProcessing: false,
      self: getUserData(),
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      robotStyle: [
        { value: 'serious', text: this.$i18n.tc('serious') },
        { value: 'happy', text: this.$i18n.tc('happy') },
      ],
      modalInfo: {
        prompt_speech_pass: {
          title: i18n.tc('setting-module.prompted-speech-pass-title'),
          description: i18n.tc('setting-module.prompted-speech-pass'),
          imageSrc: '',
          steps: '',
        },
        complete_round: {
          title: i18n.tc('setting-module.allow-completing-round-afterward'),
          description: i18n.tc('setting-module.complete-round'),
          imageSrc: '/school-settings/complete-round.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>If value added students can complete problem after provided number.</li>'
                + '</ul>',
        },
        single_double_course_completion: {
          title: i18n.tc('setting-module.course-completion'),
          description: i18n.tc('setting-module.single-double-course-completion'),
          imageSrc: '/school-settings/single-double-bar.png',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to classroom</li>'
                + '<li>You will see \'Current Progress Bar\' and \'Total Progress Bar\' if Course Completion is switched to \'Double Bar\' </li>'
                + '</ul>',
        },
        speech_server_check: {
          title: i18n.tc('setting-module.speech-server-check-title'),
          description: i18n.tc('setting-module.speech-server-check'),
          imageSrc: '',
          steps: '',
        },
        skip_problem: {
          title: i18n.tc('setting-module.skip-problem'),
          description: i18n.tc('setting-module.skip-problem'),
          imageSrc: '/school-settings/skip-problem.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>If \'Skip Problem\' is enabled students can skip the problem by clicking to circles.</li>'
                + '</ul>',
        },
        default_individual_report: {
          title: i18n.tc('setting-module.default-individual-report-type'),
          imageSrc: '/school-settings/default_individual_report.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Go to Individual Report</li>'
                + '<li>You will see the report selected here by default.</li>'
                + '</ul>',
        },
        multi_practice: {
          title: i18n.t('setting-module.multi-practice-title'),
          description: i18n.t('setting-module.multi-practice-desc'),
          imageSrc: '/school-settings/multi-practice.png',
          steps: '<ul >'
                + '<li>Go to Teacher Account</li>'
                + '<li>Go to Classroom</li>'
                + '<li>Go to Calender</li>'
                + '<li>Create Event</li>'
                + '<li>After selecting Lessons, you will notice Weakest Skill Delivery options</li>'
                + '</ul>',
        },
        word_power: {
          title: i18n.t('setting-module.word-power-title'),
          description: i18n.t('setting-module.word-power-desc'),
          imageSrc: '/school-settings/word-power.png',
          steps: '<ul >'
                + '<li>Go to Classroom</li>'
                + '<li>Select lesson</li>'
                + '<li>Go to Student Lab</li>'
                + '<li>Click on word</li>'
                + '</ul>',
        },
        robot_style: {
          title: i18n.t('setting-module.robot-style-title'),
          description: i18n.t('setting-module.robot-style-desc'),
          imageSrc: '/school-settings/robot-style.png',
          steps: '<ul >'
                + '<li>Go to Student Lab</li>'
                + '<li>On bottom right corner you will see chat bot </li>'
                + '</ul>',
        },
        language_level_indicator: {
          title: i18n.t('setting-module.language-level-indicator-title'),
          description: i18n.t('setting-module.language-level-indicator-title-desc'),
          imageSrc: '/school-settings/language-level-indicator.png',
          steps: '<ul >'
              + '<li>Go to Student Lab</li>'
              + '<li>At the bottom you can see language level </li>'
              + '</ul>',
        },
        copy_paste: {
          title: i18n.tc('setting-module.copy-paste'),
          description: i18n.tc('setting-module.copy-paste-info'),
          imageSrc: '',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>If disabled student won\'t be able to copy paste words from the problem statement into the answer input field</li>'
                + '</ul>',
        },
        overriding_last_homework: {
          title: i18n.tc('setting-module.overriding-last-homework-title'),
          description: i18n.tc('setting-module.overriding-last-homework-info'),
          imageSrc: '',
          steps: '',
        },
      },
    }
  },
  mounted() {
    this.$emit('toogleOverlay', true)
  },
  methods: {
    updateSchoolSetting(type) {
      const value = this.optionsLocal[type]
      this.isProcessing = true
      useJwt.updateUserSettings({ key: type, value, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
    handleLangLevelChange() {
      const value = JSON.stringify(this.optionsLocal.lang_level_indicator)
      useJwt.updateUserSettings({ key: 'lang_level_indicator', value }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      })
    },
  },
}
</script>
<style lang="scss">
  .modal-steps
   {
    ul {
    padding-left: 23px;
      li{
        list-style: decimal;
      }
    }
   }
</style>
