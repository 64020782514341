import { render, staticRenderFns } from "./CameraSettings.vue?vue&type=template&id=32c38fa0&scoped=true&"
import script from "./CameraSettings.vue?vue&type=script&lang=js&"
export * from "./CameraSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c38fa0",
  null
  
)

export default component.exports