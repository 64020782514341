<template>
  <BOverlay :show="isProcessing">
    <h3 class="mb-2">
      {{ $t('setting-module.other-settings') }}
    </h3>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="40%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="20%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>{{ $t('setting-module.school-daily-time-title') }}</b-td>
          <b-td>
            <b-form-input
              v-model="optionsLocal.study_time_daily"
              style="width: 80px;"
              class="custom-control-primary"
              type="number"
              placeholder="Time in hours"
              @change="(value)=>saveUserSetting({key:'study_time_daily',value})"
            />
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('study_time_daily')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.font-title') }}</b-td>
          <b-td>
            <b-form-select
              v-model="optionsLocal.font"
              :options="fonts"
              @change="(value)=>saveUserSetting({key:'font',value})"
            />
            <span style="font-size: 12px">
              {{ $t('setting-module.demo-font-title') }}: <br>
              <span :style="fontSelected">
                A B C D E F G H I J k L M N O P Q R S T U V W X Y Z <br>
                a b c d e f g h i j k l m n o p q r s t u v w x y z
              </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('font')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.student-dashboard-title') }}</b-td>
          <b-td>
            <b-form-select
              v-model="optionsLocal.student_dashboard.value"
              :options="dashboardOptions"
              @change="(value)=>saveUserSetting({key:'student_dashboard',value})"
            />

          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('student_dashboard')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>{{ $t('setting-module.language-translation') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.language_translate) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                id="language-settings"
                v-model="optionsLocal.language_translate"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('language_translate')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }}  </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('language_translate')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.speech-writing-conversion') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.speech_writing_conversion) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                v-model="optionsLocal.speech_writing_conversion.value"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('speech_writing_conversion')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }}  </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('speech_writing_conversion')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('self-evaluation.popup-for-students') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.personality_popup) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                v-model="optionsLocal.personality_popup"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('personality_popup')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }} </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('personality_popup')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td>{{ $t('setting-module.fancy-audio-player') }}</b-td>
          <b-td>
            <span v-if="Number(optionsLocal.district?.fancy_audio_player) === 0">
              {{ $t('setting-module.disabled-by-district') }}
            </span>
            <span
              v-else
              class="d-flex align-items-baseline"
            >
              <b-form-checkbox
                v-model="optionsLocal.fancy_audio_player"
                value="1"
                unchecked-value="0"
                switch
                style="margin-right: -16px"
                @change="updateSchoolSetting('fancy_audio_player')"
              /> <span class="ml-2">{{ $t('setting-module.off-on') }} </span>
            </span>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="$t('setting-module.help-guide')"
              variant="outline-success"
              class="btn-icon"
              @click="showHelpGuide('fancy_audio_player')"
            >
              <feather-icon icon="InfoIcon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </BOverlay>
</template>

<script>
import {
  // BRow, BCol,
  BFormInput, BFormSelect, BFormCheckbox, /* BCard,  BButton, */
  BTableSimple,
  BThead, BTbody, BTr, BTh, BTd, BButton, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'

export default {
  name: 'OtherSettings',
  components: {
    BOverlay,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  props: {
    optionsLocal: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // generalData: {},
      // optionsLocal: {},
      isProcessing: false,
      fonts: [
        { value: null, text: 'Please select font' },
        { value: 'Arial', text: 'Arial' },
        { value: 'Times New Roman', text: 'Times New Roman' },
        { value: 'Georgia', text: 'Georgia' },
        { value: 'Verdana', text: 'Verdana' },
        { value: 'Roboto', text: 'Roboto' },
        { value: 'Open Sans', text: 'Open Sans' },
      ],
      dashboardOptions: [
        { text: 'Default', value: 'default' },
        { text: 'Simple Landing Page (SLP)', value: 'slp' },
        { text: 'Classroom Lists', value: 'class_lists' },
      ],
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        study_time_daily: {
          title: i18n.tc('setting-module.school-daily-time-title'),
          description: i18n.tc('setting-module.school-daily-time'),
          imageSrc: '',
          steps: '',
        },
        font: {
          title: i18n.tc('setting-module.font-title'),
          description: i18n.tc('setting-module.font'),
          imageSrc: '/school-settings/distschool-font.png',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to classroom</li>'
                + '</ul>',
        },
        student_dashboard: {
          title: i18n.tc('setting-module.student-dashboard-title'),
          description: i18n.tc('setting-module.student-dashboard-info'),
          imageSrc: '/school-settings/student-dashboard.png',
          steps: '',
        },
        language_translate: {
          title: i18n.tc('setting-module.language-translation'),
          description: i18n.tc('setting-module.language-translation-info'),
          imageSrc: '',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to classroom</li>'
                + '</ul>',
        },
        copy_paste: {
          title: i18n.tc('setting-module.copy-paste'),
          description: i18n.tc('setting-module.copy-paste-info'),
          imageSrc: '',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>If enable student won\'t be able to copy paste words from the problem statement into the answer input field</li>'
                + '</ul>',
        },
        speech_writing_conversion: {
          title: i18n.tc('setting-module.speech-writing-conversion'),
          description: i18n.tc('setting-module.speech-writing-conversion-info'),
          imageSrc: '',
          steps: '',
        },
        fancy_audio_player: {
          title: i18n.tc('setting-module.fancy-audio-player'),
          description: i18n.tc('setting-module.fancy-audio-player'),
          imageSrc: '/school-settings/fancy-audio-player.png',
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>If enable fancy audio play will be available</li>'
                + '</ul>',
        },
        personality_popup: {
          title: i18n.tc('self-evaluation.popup-for-students'),
          description: i18n.tc('setting-module.personality-popup-info'),
          imageSrc: '/school-settings/personality-popup.png',
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Student Personality Popup will be shown if the student hasn\'t filled the personality form yet.</li>',
        },
      },
    }
  },
  computed: {
    fontSelected() {
      return { 'font-family': this.optionsLocal.font }
    },
  },
  mounted() {
    this.$emit('toogleOverlay', true)
  },
  methods: {
    saveUserSetting(d) {
      if (d.key === 'font') {
        this.optionsLocal.font = d.value
      }
      // eslint-disable-next-line no-param-reassign
      d.forceSync = this.$store.state.taskHelper.forceSyncSettingToSchool
      this.isProcessing = true
      useJwt.updateUserSettings(d).then(res => {
        this.showSuccessMessage(res)
        if (d.key === 'font') {
          const userData = JSON.parse(localStorage.getItem('userData'))
          userData.font = d.value
          localStorage.setItem('userData', JSON.stringify(userData))
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    updateSchoolSetting(type) {
      const value = this.optionsLocal[type]
      this.isProcessing = true
      useJwt.updateUserSettings({ key: type, value, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type) {
      this.showModal = true
      this.modalData = {
        title: this.modalInfo[type].title,
        imgSrc: this.modalInfo[type].imageSrc,
        description: this.modalInfo[type].description,
        steps: this.modalInfo[type].steps,
      }
    },
  },
}
</script>
