<template>
  <BOverlay :show="isProcessing">
    <h3 class="mb-2">
      {{ $t('setting-module.visibility-options') }}
    </h3>
    <b-table-simple
      responsive
      class="visibility-options"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            width="30%"
          >
            {{ $t('setting-module.options') }}
          </b-th>
          <b-th
            width="30%"
          >
            {{ $t('user-module.user-table.user-type') }}
          </b-th>
          <b-th
            width="30%"
          >
            {{ $t('setting-module.switch') }}
          </b-th>
          <b-th
            width="10%"
          >
            {{ $t('setting-module.help-guide') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template
          v-for="([key, value]) in Object.entries(modalInfo)"
        >
          <template v-if="!value.children">
            <b-tr :key="key">
              <b-td>{{ value.title }} </b-td>
              <b-td>
                <span v-if="(key==='report_issue' && (isADistrict || optionsLocal.district?.[key] !== '1'))">
                  <b-form-checkbox-group
                    :id="`user_type_${key}`"
                    v-model="optionsLocal[key].user_type"
                    :options="childUsers"
                    class="m-0"
                    @change="updateSchoolSetting(key)"
                  />
                </span>
                <span v-else>
                  ---
                </span>
              </b-td>
              <b-td>
                <div>
                  <p v-if="optionsLocal.district?.[key] === '1'">
                    {{ $t('setting-module.hidden-by-district') }}
                  </p>
                  <span
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <b-form-checkbox
                      :id="key"
                      v-model="optionsLocal[key].value"
                      value="0"
                      unchecked-value="1"
                      switch
                      style="margin-right: -16px"
                      @change="updateSchoolSetting(key)"
                    /><span class="ml-2">{{ $t('setting-module.hide-show') }}</span>
                  </span>
                </div>
              </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                  variant="outline-success"
                  class="btn-icon"
                  @click="showHelpGuide(key)"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </b-td>
            </b-tr>
          </template>
          <template v-else>
            <b-tr :key="key"
                  class="table-secondary"
            >
              <b-td colspan="4">
                <h4 class="mb-0">
                  {{ value.title }}
                </h4>
              </b-td>
            </b-tr>
            <template
              v-for="([childKey, childValue]) in Object.entries(value.children)"
            >
              <b-tr :key="childKey">
                <b-td> {{ childValue.title }}</b-td>
                <b-td>---- </b-td>
                <b-td>
                  <p v-if="optionsLocal.district?.[childKey] === '1'">
                    {{ $t('setting-module.hidden-by-district') }}
                  </p>
                  <span
                    v-else
                    class="d-flex align-items-baseline"
                  >
                    <b-form-checkbox
                      :id="childKey"
                      v-model="optionsLocal[childKey].value"
                      value="0"
                      unchecked-value="1"
                      switch
                      style="margin-right: -16px"
                      @change="updateSchoolSetting(childKey)"
                    />
                    <span class="ml-2">{{ $t('setting-module.hide-show') }}</span>
                  </span>
                </b-td>
                <b-td>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="$t('setting-module.help-guide')"
                    variant="outline-success"
                    class="btn-icon"
                    @click="showHelpGuide(childKey, key)"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button></b-td>
              </b-tr>
            </template>
            <b-tr :key="`${key}_b`">
              <b-td colspan="4">
                &nbsp;
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <setting-info-modal
      :show="showModal"
      :modal-data="modalData"
      @close="showModal = false"
    />
  </BOverlay>
</template>
<script>
import {
  BFormCheckbox, BTableSimple, BFormCheckboxGroup,
  BThead, BTbody, BTr, BTh, BTd, BButton, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import SettingInfoModal from '@/views/common/components/SettingInfoModal.vue'
import { USER_TYPE_DISTRICT } from '@/const/userType'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BOverlay,
    BFormCheckbox,
    BTableSimple,
    BFormCheckboxGroup,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    SettingInfoModal,
  },
  props: {
    optionsLocal: {
      type: Object,
      default: () => {},
    },
    childUsers: {
      type: Array,
      default: () => [],
    },
    currentUserType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isProcessing: false,
      self: getUserData(),
      showModal: false,
      modalData: {
        title: '',
        imgSrc: '',
        description: '',
        steps: '',
      },
      modalInfo: {
        badge_bar: {
          title: i18n.tc('setting-module.badge-bar-title'),
          imageSrc: '/school-settings/badge-bar.png',
          description: i18n.tc('setting-module.badge-bar'),
          steps: '<ul >'
                + '<li>Login In to Student/Teacher Account</li>'
                + '<li>You can see below navigation bar</li>'
                + '</ul>',
        },
        menu: {
          title: i18n.tc('setting-module.menus'),
          children: {
            engagement_menu: {
              title: i18n.tc('setting-module.engagement-menu-title'),
              imageSrc: '/school-settings/engagement-menu.png',
              description: i18n.tc('setting-module.engagement-menu'),
              steps: '<ul >'
                    + '<li>Login In to Student Account</li>'
                    + '<li>Navigate to the navigation bar</li>'
                    + '</ul>',
            },
            user_menu: {
              title: i18n.tc('setting-module.user-menu-title'),
              imageSrc: '/school-settings/user-menu.png',
              description: i18n.tc('setting-module.user-menu-desc'),
              steps: '<ul >'
                    + '<li>Login In to Student Account</li>'
                    + '<li>Navigate to the navigation bar</li>'
                    + '</ul>',
            },
            nav_chat: {
              title: i18n.tc('setting-module.nav-chat-title'),
              imageSrc: '/school-settings/nav-chat.png',
              description: i18n.tc('setting-module.nav-chat-desc'),
              steps: '<ul >'
                    + '<li>Go to User account</li>'
                    + '<li>You will notice Chat icon on navigation bar</li>'
                    + '</ul>',
            },
            nav_mapper: {
              title: i18n.tc('setting-module.nav-mapper-title'),
              imageSrc: '/school-settings/nav-mapper.png',
              description: i18n.tc('setting-module.nav-mapper-desc'),
              steps: '<ul >'
                    + '<li>Go to School Account</li>'
                    + '<li>In nav bar under settings, you will notice Mapper</li>'
                    + '</ul>',
            },
            nav_profile: {
              title: i18n.tc('setting-module.nav-profile-title'),
              imageSrc: '/school-settings/nav-profile.png',
              description: i18n.tc('setting-module.nav-profile-desc'),
              steps: '<ul >'
                    + '<li>Go to User Account</li>'
                    + '<li>Click on user Avatar</li>'
                    + '<li>You will notice Profile Menu</li>'
                    + '</ul>',
            },
            nav_general_setting: {
              title: i18n.tc('setting-module.nav-general-setting-title'),
              imageSrc: '/school-settings/nav-general-setting.png',
              description: i18n.tc('setting-module.nav-general-setting-desc'),
              steps: '<ul >'
                    + '<li>Go to User Account</li>'
                    + '<li>Click on user Avatar</li>'
                    + '<li>You will notice General Setting Menu</li>'
                    + '</ul>',
            },
            nav_notification: {
              title: i18n.tc('setting-module.nav-notification-title'),
              imageSrc: '/school-settings/nav-notification.png',
              description: i18n.tc('setting-module.nav-notification-desc'),
              steps: '<ul >'
                    + '<li>Go to User Account</li>'
                    + '<li>You will notice Bell icon on navigation bar</li>'
                    + '</ul>',
            },
          },
        },

        help_video: {
          title: i18n.tc('setting-module.help-video-title'),
          children: {
            help_video: {
              title: i18n.tc('setting-module.help-video-title'),
              imageSrc: '/school-settings/help-video.png',
              description: i18n.tc('setting-module.help-video'),
              steps: '<ul >'
                    + '<li>Login In to Student/Teacher Account</li>'
                    + '<li>You\'ll notice the Help video icon located on the right side of the page.</li>'
                    + '</ul>',
            },
            help_video_pop_up: {
              title: i18n.tc('setting-module.help-video-pop-up-title'),
              imageSrc: '',
              description: i18n.tc('setting-module.help-video-pop-up'),
              steps: '<ul >'
                    + '<li>Login In to Student/Teacher Account</li>'
                    + '<li>You\'ll find that the Help video popup is accessible on every page.</li>'
                    + '</ul>',
            },
          },
        },
        report_buttons: {
          title: i18n.tc('setting-module.report-buttons-title'),
          children: {
            student_skill_chart: {
              title: i18n.tc('setting-module.skill-chart-title'),
              imageSrc: '/school-settings/skill-chart.png',
              description: i18n.tc('setting-module.skill-chart'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '</ul>',
            },
            student_diary: {
              title: i18n.tc('setting-module.student-diary-title'),
              imageSrc: '/school-settings/student-diary.png',
              description: i18n.tc('setting-module.student-diary'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Diary Chart icon on the student card</li>'
                + '</ul>',
            },
            student_rubrics: {
              title: i18n.tc('setting-module.student-rubrics-title'),
              imageSrc: '/school-settings/student-rubrics.png',
              description: i18n.tc('setting-module.student-rubrics'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Rubrics Chart icon on the student card</li>'
                + '</ul>',
            },
            student_notes: {
              title: i18n.tc('setting-module.student-notes'),
              imageSrc: '/school-settings/student-notes.png',
              description: i18n.tc('setting-module.student-note'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Notes icon on the student card</li>'
                + '</ul>',
            },
            student_individual_report: {
              title: i18n.tc('setting-module.student-individual-report-title'),
              imageSrc: '/school-settings/student-individual-report.png',
              description: i18n.tc('setting-module.student-individual-report'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Individual Report icon on the student card</li>'
                + '</ul>',
            },
          },
        },
        student_skill_graph: {
          title: i18n.tc('setting-module.skill-chart-title'),
          children: {
            language_summary_chart: {
              title: i18n.tc('report.language-skill'),
              imageSrc: '/school-settings/language-summary-chart.png',
              description: i18n.tc('setting-module.language-summary-chart-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '<li>Click on Skill Chart icon</li>'
                + '</ul>',
            },
            skill_summary_chart: {
              title: i18n.tc('report.study-skill'),
              imageSrc: '/school-settings/skill-summary-chart.png',
              description: i18n.tc('setting-module.skill-summary-chart-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '<li>Click on Skill Chart icon</li>'
                + '</ul>',
            },
            student_role_chart: {
              title: i18n.tc('report.student-comparative-role-stats'),
              imageSrc: '/school-settings/student-role-chart.png',
              description: i18n.tc('setting-module.student-role-chart-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '<li>Click on Skill Chart icon</li>'
                + '</ul>',
            },
            student_note_chart: {
              title: i18n.tc('report.student-comparative-notes-stats'),
              imageSrc: '/school-settings/student-note-chart.png',
              description: i18n.tc('setting-module.student-note-chart-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '<li>Click on Skill Chart icon</li>'
                + '</ul>',
            },
            student_game_report: {
              title: i18n.tc('report.game-report'),
              imageSrc: '/school-settings/student-game-report.png',
              description: i18n.tc('setting-module.student-game-report-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>You\'ll notice Skill Chart icon on the student card</li>'
                + '<li>Click on Skill Chart icon</li>'
                + '</ul>',
            },
          },
        },
        note: {
          title: i18n.tc('setting-module.note-title'),
          imageSrc: '/school-settings/notes.png',
          description: i18n.tc('setting-module.note'),
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>To the right side of the page, under \'Lesson List,\' you\'ll notice the \'Note\' in the lesson card.</li>'
                + '</ul>',
        },
        make_request: {
          title: i18n.tc('setting-module.make-request-title'),
          imageSrc: '/school-settings/make-request.png',
          description: i18n.tc('setting-module.make-request'),
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>To the right side of the page, under \'Lesson List,\' you\'ll notice the \'Make Request\' in the lesson card.</li>'
                + '</ul>',
        },
        code_standards: {
          title: i18n.tc('setting-module.code-standards-title'),
          imageSrc: '/school-settings/code-standards.png',
          description: i18n.tc('setting-module.code-standards'),
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>You\'ll find the \'Code and Standards\' under Lesson Info</li>'
                + '</ul>',
        },
        classroom: {
          title: i18n.tc('labels.classroom'),
          children: {
            teacher: {
              title: i18n.tc('setting-module.teacher-title'),
              imageSrc: '/school-settings/teacher.png',
              description: i18n.tc('setting-module.teacher-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            co_teachers: {
              title: i18n.tc('setting-module.co-teacher-title'),
              imageSrc: '/school-settings/co-teacher.png',
              description: i18n.tc('setting-module.co-teacher-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            owner: {
              title: i18n.tc('setting-module.owner-title'),
              imageSrc: '/school-settings/owner.png',
              description: i18n.tc('setting-module.owner-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            school: {
              title: i18n.tc('setting-module.school-title'),
              imageSrc: '/school-settings/school.png',
              description: i18n.tc('setting-module.school-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },

            classroom_list: {
              title: i18n.tc('setting-module.classroom-list-title'),
              imageSrc: '/school-settings/classroom-list.png',
              description: i18n.tc('setting-module.classroom-list-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            class_test: {
              title: i18n.tc('setting-module.class-test-title'),
              imageSrc: '/school-settings/class-test.png',
              description: i18n.tc('setting-module.class-test-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            view_essay: {
              title: i18n.tc('setting-module.view-essay-title'),
              imageSrc: '/school-settings/view-essay.png',
              description: i18n.tc('setting-module.view-essay-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            show_script: {
              title: i18n.tc('setting-module.show-script-title'),
              imageSrc: '/school-settings/show-script.png',
              description: i18n.tc('setting-module.show-script-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
            in_progress_completed: {
              title: i18n.tc('setting-module.in-progress-completed-title'),
              imageSrc: '/school-settings/in-progress-completed.png',
              description: i18n.tc('setting-module.in-progress-completed-desc'),
              steps: '<ul>'
                + '<li>Go to classroom</li>'
                + '<li>Classroom detail</li>'
                + '</ul>',
            },
          },
        },
        student_lab: {
          title: i18n.tc('setting-module.student-lab-title'),
          children: {
            high_score: {
              title: i18n.tc('setting-module.high-score-title'),
              imageSrc: '/school-settings/high-score.png',
              description: i18n.tc('setting-module.high-score'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, within the green section, You\'ll notice \'High Points and Your Points\'.</li>'
                + '</ul>',
            },
            progress_bar: {
              title: i18n.tc('setting-module.progress-bar-title'),
              imageSrc: '/school-settings/progress-bar.png',
              description: i18n.tc('setting-module.progress-bar'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, within the green section, You\'ll notice Progress bar.</li>'
                + '</ul>',
            },
            rti: {
              title: i18n.tc('setting-module.real-time-indicator'),
              imageSrc: '/school-settings/rti.png',
              description: i18n.tc('setting-module.rti'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, at the end of the page, You\'ll notice \'Real Time Indicators\'.</li>'
                + '</ul>',
            },
          },
        },
        streak_persistence: {
          title: i18n.tc('setting-module.streak-persistence-title'),
          imageSrc: '',
          description: i18n.tc('setting-module.streak-persistence'),
          steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>Click Start button</li>'
                + '<li>In the Student Lab, after giving certain number of currect answers in a row, you\'ll get \'Streak and Persistence\' pop up</li>'
                + '</ul>',
        },
        auto_play: {
          title: i18n.tc('setting-module.auto-play-title'),
          imageSrc: '/school-settings/auto-play.png',
          description: i18n.tc('setting-module.streak-persistence'),
          steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Click on Course</li>'
                + '<li>Select Lesson</li>'
                + '<li>In Lesson page auto start will be visible</li>'
                + '</ul>',
        },
        academic_skills: {
          title: i18n.tc('setting-module.academic-skills-title'),
          children: {
            problem_solving_skills: {
              title: i18n.tc('setting-module.problem-solving-skills-title'),
              imageSrc: '/school-settings/problem-solving-skills.png',
              description: i18n.tc('setting-module.problem-solving-skills-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Academic Skills tab</li>'
                + '</ul>',
            },
            proficiency_on_lessons: {
              title: i18n.tc('setting-module.proficiency-on-lessons-title'),
              imageSrc: '/school-settings/proficiency-on-lessons.png',
              description: i18n.tc('setting-module.proficiency-on-lessons-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Academic Skills tab</li>'
                + '</ul>',
            },
            lesson_completion_rate: {
              title: i18n.tc('setting-module.lesson-completion-rate-title'),
              imageSrc: '/school-settings/lesson-completion-rate.png',
              description: i18n.tc('setting-module.lesson-completion-rate-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Academic Skills tab</li>'
                + '</ul>',
            },
          },
        },
        language_skills_report: {
          title: i18n.tc('setting-module.language-skills-report-title'),
          imageSrc: '/school-settings/language-skills-report.png',
          description: i18n.tc('setting-module.language-skills-report-desc'),
          steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Language Skills tab</li>'
                + '</ul>',
        },
        study_report: {
          title: i18n.tc('setting-module.study-skills-title'),
          children: {
            attendance_report: {
              title: i18n.tc('setting-module.attendance-report-title'),
              imageSrc: '/school-settings/attendance-report.png',
              description: i18n.tc('setting-module.attendance-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Study Skills tab</li>'
                + '</ul>',
            },
            activity_report: {
              title: i18n.tc('setting-module.activity-report-title'),
              imageSrc: '/school-settings/activity-report.png',
              description: i18n.tc('setting-module.activity-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Study Skills tab</li>'
                + '</ul>',
            },
            schedule_report: {
              title: i18n.tc('setting-module.schedule-report-title'),
              imageSrc: '/school-settings/schedule-report.png',
              description: i18n.tc('setting-module.schedule-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Study Skills tab</li>'
                + '</ul>',
            },
          },
        },
        social_skills: {
          title: i18n.tc('setting-module.social-skills-title'),
          children: {
            note_report: {
              title: i18n.tc('setting-module.note-report-title'),
              imageSrc: '/school-settings/note-report.png',
              description: i18n.tc('setting-module.note-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Social Skills tab</li>'
                + '</ul>',
            },
            chat_report: {
              title: i18n.tc('setting-module.chat-report-title'),
              imageSrc: '/school-settings/chat-report.png',
              description: i18n.tc('setting-module.chat-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Social Skills tab</li>'
                + '</ul>',
            },
            role_report: {
              title: i18n.tc('setting-module.role-report-title'),
              imageSrc: '/school-settings/role-report.png',
              description: i18n.tc('setting-module.role-report-desc'),
              steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Social Skills tab</li>'
                + '</ul>',
            },
          },
        },
        game_report: {
          title: i18n.tc('setting-module.game-report-title'),
          imageSrc: '/school-settings/game-report.png',
          description: i18n.tc('setting-module.game-report-desc'),
          steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Game Report tab</li>'
                + '</ul>',
        },
        class_test_report: {
          title: i18n.tc('setting-module.class-test-report-title'),
          imageSrc: '/school-settings/class-test-report.png',
          description: i18n.tc('setting-module.class-test-report-desc'),
          steps: '<ul >'
                + '<li>Login with Teacher account</li>'
                + '<li>Go to classroom</li>'
                + '<li>Under Report select Class Report</li>'
                + '<li>Select Class Test Report tab</li>'
                + '</ul>',
        },
        chat_icon: {
          title: i18n.tc('setting-module.chat-icon-title'),
          imageSrc: '/school-settings/chat-icon.png',
          description: i18n.tc('setting-module.chat-icon-desc'),
          steps: '<ul >'
                + '<li>Login with Student account</li>'
                + '<li>You will notice chat icon on bottom right corner</li>'
                + '</ul>',
        },
        pdf: {
          title: i18n.tc('setting-module.pdf-title'),
          imageSrc: '/school-settings/lesson-pdf.png',
          description: i18n.tc('setting-module.pdf-desc'),
          steps: '<ul >'
                + '<li>Login with Student account</li>'
                + '<li>Go to Course</li>'
                + '<li>Go to Student Lab</li>'
                + '<li>You will notice Lesson Pdf Link</li>'
                + '</ul>',
        },
        ebook: {
          title: i18n.tc('setting-module.ebook-title'),
          imageSrc: '/school-settings/ebook.png',
          description: i18n.tc('setting-module.ebook-desc'),
          steps: '<ul >'
                + '<li>Login with Student account</li>'
                + '<li>Go to Course</li>'
                + '<li>You will notice ebook on lesson</li>'
                + '</ul>',
        },
        create_class: {
          title: i18n.tc('setting-module.create-class-title'),
          imageSrc: '/school-settings/create-classroom.png',
          description: i18n.tc('setting-module.create-class-desc'),
          steps: '<ul >'
                + '<li>Go to Classes</li>'
                + '<li>You will notice Create Classroom button</li>'
                + '</ul>',
        },
        show_no_of_students: {
          title: i18n.tc('setting-module.show-number-of-student-title'),
          imageSrc: '/school-settings/no-of-students.png',
          description: i18n.tc('setting-module.show-number-of-student-desc'),
          steps: '<ul >'
                + '<li>Go to Classes</li>'
                + '<li>You will notice No of students in Classroom cards</li>'
                + '</ul>',
        },
        student_card: {
          title: i18n.tc('setting-module.display-self-card-title'),
          imageSrc: '/school-settings/display-self.png',
          description: i18n.tc('setting-module.student-title-info'),
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to Classroom</li>'
                + '<li>Student will only see owns card</li>'
                + '</ul>',
        },
        report_issue: {
          title: i18n.tc('setting-module.enable-report-issue-title'),
          imageSrc: '/school-settings/enable-report-issues.png',
          description: i18n.tc('setting-module.enable-report-issue-desc'),
          steps: '<ul >'
                + '<li>Login In to Student Account</li>'
                + '<li>Go to Course</li>'
                + '<li>Go to Student Lab</li>'
                + '<li>You will notice "Report Issue" floating icon</li>'
                + '</ul>',
        },
        lang_level: {
          title: i18n.tc('setting-module.lang-level-title'),
          imageSrc: '/school-settings/lang-level.png',
          description: i18n.tc('setting-module.lang-level-desc'),
          steps: '<ul >'
                + '<li>Go to Course</li>'
                + '<li>Click Student Diary</li>'
                + '<li>You will notice "Report Issue" floating icon</li>'
                + '</ul>',
        },
        student_point: {
          title: i18n.tc('setting-module.student-points-title'),
          imageSrc: '/school-settings/student-points.png',
          description: i18n.tc('setting-module.student-points-desc'),
          steps: '<ul >'
                + '<li>Go to Classroom</li>'
                + '<li>You will notice "Points" in student card</li>'
                + '</ul>',
        },
        student_point_popup: {
          title: i18n.tc('setting-module.student-points-popup-title'),
          imageSrc: '/school-settings/student-points-detail.png',
          description: i18n.tc('setting-module.student-points-popup-desc'),
          steps: '<ul >'
                + '<li>Go to Classroom</li>'
                + '<li>You will notice "Points" in student card</li>'
                + '<li>Click Points to view Point detail</li>'
                + '</ul>',
        },
        virtual_keyboard: {
          title: i18n.tc('setting-module.virtual-keyboard-title'),
          imageSrc: '/school-settings/student-keyboard.png',
          description: i18n.tc('setting-module.virtual-keyboard-desc'),
          steps: '<ul >'
                + '<li>Go to Student Account</li>'
                + '<li>You will notice virtual "Keyboard" </li>'
                + '</ul>',
        },
        class_card_courses: {
          title: i18n.tc('setting-module.class-card-courses-title'),
          imageSrc: '/school-settings/class_card_courses.png',
          description: i18n.tc('setting-module.class-card-courses-desc'),
          steps: '<ul >'
                + '<li>Go to classes</li>'
                + '<li>You will notice "courses" in classroom card </li>'
                + '</ul>',
        },
        individual_report: {
          title: i18n.tc('setting-module.student-individual-report-title'),
          children: {
            stats_summary: {
              title: i18n.tc('setting-module.stats-summary-title'),
              imageSrc: '/school-settings/stats-summary.png',
              description: i18n.tc('setting-module.stats-summary-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>Stats Summary under student details</li>'
                + '</ul>',
            },
            course_graph: {
              title: i18n.tc('setting-module.course-graph-title'),
              imageSrc: '/school-settings/course-graph.png',
              description: i18n.tc('setting-module.course-graph-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>Show Graph button in lesson table </li>'
                + '</ul>',
            },
            lesson_details: {
              title: i18n.tc('setting-module.lesson-details-title'),
              imageSrc: '/school-settings/lesson-details.png',
              description: i18n.tc('setting-module.lesson-details-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>Lesson detail report after clicking show details</li>'
                + '</ul>',
            },
            user_credentials: {
              title: i18n.tc('setting-module.user-credentials-title'),
              imageSrc: '/school-settings/user-credentials.png',
              description: i18n.tc('setting-module.user-credentials-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>You will notice user credentials</li>'
                + '</ul>',
            },
            summary: {
              title: i18n.tc('setting-module.summary-title'),
              imageSrc: '/school-settings/summary.png',
              description: i18n.tc('setting-module.summary-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>You will notice user credentials</li>'
                + '</ul>',
            },
            summary_score: {
              title: i18n.tc('setting-module.summary-score-title'),
              imageSrc: '/school-settings/summary-score.png',
              description: i18n.tc('setting-module.summary-score-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>You will notice user credentials</li>'
                + '</ul>',
            },
            summary_status: {
              title: i18n.tc('setting-module.summary-status-title'),
              imageSrc: '/school-settings/summary-status.png',
              description: i18n.tc('setting-module.summary-status-desc'),
              steps: '<ul >'
                + '<li>Go to classroom</li>'
                + '<li>Under Student card click Show Individual Report</li>'
                + '<li>You will notice user credentials</li>'
                + '</ul>',
            },
          },
        },
        higher_level_report: {
          title: i18n.tc('setting-module.higher-level-report-title'),
          children: {
            report_head: {
              title: i18n.tc('setting-module.higher-level-report-table-title'),
              imageSrc: '/school-settings/report-head.png',
              description: i18n.tc('setting-module.higher-level-report-table-desc'),
              steps: '<ul >'
                + '<li>Go to Student Account</li>'
                + '<li>Go to menu and select report and Higher Level Report </li>'
                + '<li>You will notice Report table</li>'
                + '</ul>',
            },
          },
        },
        lesson_page: {
          title: 'Lesson page',
          children: {
            lesson_result: {
              title: i18n.tc('setting-module.lesson-result-title'),
              imageSrc: '/school-settings/lesson-result.png',
              description: i18n.tc('setting-module.lesson-result-desc'),
              steps: '<ul >'
                + '<li>Go to Lesson</li>'
                + '<li>Start and complete any lesson</li>'
                + '<li>At the end of the lesson in congratulation page, you will notice show Result</li>'
                + '</ul>',
            },
          },
        },
      },
    }
  },
  computed: {
    isADistrict() {
      return this.self.usertype === USER_TYPE_DISTRICT
    },
  },
  methods: {
    updateSchoolSetting(type) {
      const { value } = this.optionsLocal[type]
      const userType = this.optionsLocal[type]?.user_type
      this.isProcessing = true
      useJwt.updateUserSettings({
        key: type, value, userType, forceSync: this.$store.state.taskHelper.forceSyncSettingToSchool,
      }).then(() => {
        this.showSuccessMessage({ data: { message: 'Permission updated' } })
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    showHelpGuide(type, parentType = null) {
      this.modalData = {
        title: !parentType ? this.modalInfo[type].title : this.modalInfo[parentType].children[type].title,
        imgSrc: !parentType ? this.modalInfo[type].imageSrc : this.modalInfo[parentType].children[type].imageSrc,
        description: !parentType ? this.modalInfo[type].description : this.modalInfo[parentType].children[type].description,
        steps: !parentType ? this.modalInfo[type].steps : this.modalInfo[parentType].children[type].steps,
      }
      this.$nextTick(() => {
        this.showModal = true
      })
    },
  },
}
</script>
<style lang="scss">
  .dark-layout {
    .visibility-options{
      .table-secondary {
        background-color: #5e5873
      }
    }
  }
  .modal-steps
   {
    ul {
    padding-left: 23px;
      li{
        list-style: decimal;
      }
    }
   }
   .nested-skills {
    td {
      border-top: unset !important;
    }
   }
</style>
